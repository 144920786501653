
export function getQueryVariable (name) {
  const reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
  if (reg.test(window.location.href)) {
    return unescape(RegExp.$2.replace(/\+/g, " "))
  } else {
    return false;
  }
}

export function getUrlParam (url, name) {  // 构造一个含有目标参数的正则表达式对象 
  const reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
  if (reg.test(url)) {
    return unescape(RegExp.$2.replace(/\+/g, " "))
  } else {
    return false;
  }
}

