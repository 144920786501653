<template>
  <div class="qy">
    <div class="banner">
      <van-image class="image" :src="require('@/assets/banner.png')"></van-image>
    </div>
    <van-cell-group class="fromeCode" inset>
      <div class="title">
        <van-image :src="require('@/assets/title.png')"></van-image>
      </div>
      <div class="goodsname">
        <van-cell :value="goodsname" />
      </div>
      <van-form label-width="4.5em" @failed="onSubmit" ref="formData" colon>
        <van-field
          styly="margin-bottom:20px"
          name="充值账号"
          label="充值账号"
          placeholder="请输入要充值的手机号"
          v-model="account"
          :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <div style="margin: 16px 0">
          <van-button
            color="linear-gradient(to right, #e77638, #fb8777)"
            style="width: 100%"
            round
            @click="exChange"
            >确认兑换</van-button
          >
        </div>
      </van-form>
      <van-divider>使用说明</van-divider>
      <div style="height: auto">
        <van-cell>
          <div v-html="description"></div>
        </van-cell>
      </div>
    </van-cell-group>
    <van-dialog
      class="dialogClas"
      v-model="tipShow"
      title="提示"
      show-cancel-button
      confirm-button-color="red"
      cancel-button-text="修改"
      @confirm="confirmBtn"
    >
      <div>
        <p style="text-align: center">请核对充值账号</p>
        <p
          style="
            color: red;
            font-size: 20px;
            font-weight: 600;
            margin: 10px 0;
            text-align: center;
          "
        >
          {{ account }}
        </p>
      </div>
    </van-dialog>
    <van-dialog
      v-model="show"
      title="兑换成功"
      cancel-button-text="继续兑换"
      show-cancel-button
      @confirm="checkOrder"
      @cancel="segue"
    >
      <ul class="ulclass">
        <li class="liItem">
          <div class="tipTitle">商品：</div>
          <div class="tipValue">
            {{ goodsname }}
          </div>
        </li>
        <li class="liItem_2">
          <div class="tipTitle">优惠券：</div>
          <div class="tipValue">{{ coupon }}</div>
        </li>
        <li class="liItem">
          <div class="tipTitle">充值账号：</div>
          <div class="tipValue">{{ account }}</div>
        </li>
      </ul>
    </van-dialog>
  </div>
</template>
<script>
import { api_exchange, api_goods } from "../api/clientOrder";
import { sessionStorage } from "../utils/storage";
import { getQueryVariable } from "../utils/index";
export default {
  components: {},
  data() {
    return {
      account: "",
      goodID: "",
      goodsname: "",
      description: "",
      show: false,
      coupon: "",
      token: "",
      cid: "",
      tipShow: false,
    };
  },
  created() {
    if (sessionStorage.get("token")) {
      const goods = sessionStorage.get("goods");
      this.coupon = sessionStorage.get("coupon");
      this.goodsname = goods.name;
      this.description = replaceLineBreaks(goods.description);
    } else {
      const gid = getQueryVariable("gid");
      this.coupon = getQueryVariable("coupon");
      this.token = getQueryVariable("token");
      const params = {
        id: gid,
      };
      api_goods(params).then((res) => {
        if (res.data.code === 0) {
          this.goodsname = res.data.data.name;
          this.description = replaceLineBreaks(res.data.data.description);
        }
      });
    }
    function replaceLineBreaks(message) {
      return message.replace(/\n/g, "<br/>");
    }
    // if (sessionStorage.get("token")) {
    //   let goods = sessionStorage.get("goods");
    //   this.coupon = sessionStorage.get("coupon");
    //   this.goodsname = goods.name;
    //   let message = goods.description;
    //   this.description = message.replace(/\n/g, "<br/>");
    // } else {
    //   getQueryVariable("gid");
    //   this.coupon = getQueryVariable("coupon");
    //   this.token = getQueryVariable("token");
    //   const params = {
    //     id: getQueryVariable("gid"),
    //   };
    //   api_goods(params).then((res) => {
    //     if (res.data.code == 0) {
    //       this.goodsname = res.data.data.name;
    //       let message = res.data.data.description;
    //       this.description = message.replace(/\n/g, "<br/>");
    //     }
    //   });
    // }
  },
  methods: {
    exChange() {
      this.$refs["formData"]
        .validate()
        .then(() => {
          this.tipShow = true;
        })
        .catch(() => {
          //验证失败
        });
    },
    confirmBtn() {
      if (sessionStorage.get("token")) {
        const params = {
          coupon: sessionStorage.get("coupon"),
          token: sessionStorage.get("token"),
          userid: this.account,
        };
        api_exchange(params).then((res) => {
          if (res.data.code == 0) {
            this.show = true;
            this.cid = res.data.data;
          } else {
            this.$dialog
              .confirm({
                title: "错误！",
                message: res.data.msg,
              })
              .then(() => {
                sessionStorage.clear();
                this.$router.push("/verfiy");
              });
          }
        });
      } else {
        const params = {
          coupon: this.coupon,
          token: this.token,
          userid: this.account,
        };
        api_exchange(params).then((res) => {
          if (res.data.code == 0) {
            this.show = true;
            this.cid = res.data.data;
          } else if (res.data.code == -116) {
            this.$dialog
              .confirm({
                title: "错误！",
                message: res.data.msg,
              })
              .then(() => {
                this.$router.push("/verfiy");
              });
          }
        });
      }
    },
    checkOrder() {
      this.$router.push({
        path: "/order",
        query: {
          coupon: this.coupon,
          cid: this.cid,
        },
      });
    },
    onSubmit(errorInfo) {
      console.log("failed", errorInfo);
    },
    segue() {
      sessionStorage.clear();
      this.$router.push("/verfiy");
    },
  },
};
</script>

<style scoped>
.qy {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #efefef;
  height: 100%;
  position: relative;
  overflow: auto;
}
.banner {
  background: linear-gradient(to bottom, #fb8777, #e77638);
  height: 22em;
  width: 100%;
  border-radius: 0 0 6em 6em;
  position: relative;
  display: flex;
  justify-content: center;
}
.fromeCode {
  position: absolute;
  /* bottom: 5em; */
  background: #fff;
  padding: 2em 1em 1em 1em;
  top: 17em;
  height: auto;
}
.title {
  display: flex;
  justify-content: center;
}
.goodsname .van-cell__value--alone {
  font-size: 22px;
  text-align: center;
  margin: 1em 0;
  font-weight: 600;
}
.image {
  position: absolute;
  bottom: 40px;
  z-index: 999;
}
::v-deep .van-form .van-cell {
  border-radius: 2em;
  background: #f8f8f8;
}

::v-deep .van-cell__title {
  color: #fb8777 !important;
  font-size: 16px;
  font-weight: 600;
}
.ulclass {
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
}
.liItem {
  display: flex;
}
.liItem_2 {
  display: flex;
  margin: 1em 0;
}
.tipTitle {
  float: left;
  width: 30%;
  text-align: right;
  padding-right: 10px;
}
.tipValue {
  float: right;
  width: 70%;
  color: #333;
}
::v-deep .van-dialog__header {
  padding: 16px 0 !important;
  color: rgb(27, 182, 86);
  font-size: 18px;
}
::v-deep .van-dialog__content .van-cell__title {
  font-size: 16px;
  font-weight: 600;
  width: 2em !important;
}
.van-dialog__content .van-cell__value {
  font-size: 15px;
  font-weight: 600;
}
.van-button__text {
  font-size: 18px;
  font-weight: 500;
}
.van-divider {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.van-cell::after {
  border: 0;
}

.van-dialog__content {
  display: flex;
  justify-content: center;
}
/* ::v-deep .van-button__content {
  background-color: #fb8777 !important;
} */
::v-deep .van-dialog__footer {
  margin-top: 1em !important;
}
::v-deep .van-button__text {
  font-weight: 600 !important;
}
</style>
